import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"

const ContactPage = () => (
  <Layout pageInfo={{ pageName: "contact" }}>
    <Container className="mt-5">
      <SEO title="Contact" />
      <h1>Contact us</h1>
      <p>You want to talk to us, book us for a show or get some records shipped over?<br />No problemo, you can try any of the below.</p>
      <Row>
        <Col className="col-12 col-lg-4">
          <h3>Email</h3>
          <p>Send us an email and we'll reply as soon as we can.<br /><a href="mailto:v_martini@hotmail.fr">v_martini@hotmail.fr</a></p>
        </Col>
        <Col className="col-12 col-lg-4">
          <h3>Phone</h3>
          <p>Call us and speak french or english (slowly).<br /><a href="tel:+33601941109">+33 (0)6 01 94 11 09</a></p>
        </Col>
        <Col className="col-12 col-lg-4">
          <h3>Messenger</h3>
          <p>If you are on Facebook, you can also talk to us there.<br /><a target="_blank" rel="noopener noreferrer" href="https://m.me/TheDirteezSwampabilly">m.me/TheDirteezSwampabilly</a></p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default ContactPage
